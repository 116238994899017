<template>
    <div class="mx-3">
        <v-toolbar color="primary" dark extended>
            <v-toolbar-title>
                Cirugia {{$route.params.id}}
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </v-toolbar>
        <v-sheet class="pa-3">
            <div class="pa-5" v-if="!this.datos">
                <v-alert type="info" text>
                    No se puede mostrar este registro. No existe o no es el médico titular
                </v-alert>
            </div>

            <div class="pa-5" v-if="datos.status == 90 ">
                <v-alert color="error" text>
                    Reagendada el {{datos.horaReagenda}}. {{datos.motivoReagenda}}
                </v-alert>
            </div>

            <div class="pa-5" v-if="datos.status == 99 ">
                <v-alert color="error" text>
                    Cancelado el {{datos.horaCancela}}. {{datos.motivoCancela}}
                </v-alert>
            </div>

            <div class="headline ">
                Generales
            </div>
            <v-divider/>

            <v-row class="mt-2">
                <v-col
                    md="6"
                    lg="1"
                    cols="12"
                >
                    <v-chip
                        v-if="(datos && 'id' in datos)"
                        small
                        :color="$store.state.quirofano.statusCirugia[datos.status].color"
                        text-color="white"
                    >
                        {{this.$store.state.quirofano.statusCirugia[datos.status].text}}
                    </v-chip>
                </v-col>
                <v-col
                    v-for="(itm, id) in campos"
                    :key="id"
                    md="6"
                    :lg="itm.cols"
                    cols="12"
                >
                    <v-text-field
                        :label="itm.label"
                        v-model="datos[itm.model]"
                        readonly
                        dense
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col
                    md="6"
                    lg="1"
                    cols="12"
                >
                    <v-checkbox
                        readonly
                        label="Urgencia"
                        v-model="datos.urgencia"
                        dense
                        hide-details
                    ></v-checkbox>
                </v-col>

                <v-col
                    cols="12"
                >
                    <v-text-field
                        label="Diagnóstico"
                        v-model="datos.padecimiento"
                        readonly
                        dense
                        hide-details
                    ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                >
                    <v-text-field
                        label="Procedimiento programado"
                        v-model="datos.procedimiento"
                        readonly
                        dense
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                        label="Procedimiento realizado"
                        v-model="datos.procedimientoRealizado"
                        rounded
                        filled
                        dense
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col
                    md="6"
                    cols="12"
                >
                    <div>
                        Solicitudes
                        <div
                            class="pa-2 text-caption"
                            v-html="datos.solicitudes"
                        ></div>
                    </div>
                </v-col>
                <v-col
                    md="6"
                    cols="12"
                >
                    <div>
                        Observaciones
                        <div
                            class="pa-2 text-caption"
                            v-html="datos.observaciones"
                        ></div>
                    </div>
                </v-col>

            </v-row>


            {{datos}}

        </v-sheet>

    </div>
</template>

<script>
export default {
    created(){
        if (/^\d+$/.test(this.$route.params.id)){
            this.params.id = this.$route.params.id
        } else {
            this.params.folio = this.$route.params.id
        }
        this.list()
    },
    data:()=>({
        params:{},
        loadingDatosTodos:false,
        datos:{},

        campos:[
            {label:'C.U.R.P.', model:'pacienteCurp', cols:'2'},
            {label:'Expediente', model:'paciente', cols:'1'},
            {label:'Nombre', model:'pacienteNombre', cols:'3'},
            {label:'Fecha de nacimiento', model:'pacienteFechaNacimiento', cols:'2'},
            {label:'Edad', model:'pacienteEdad', cols:'1'},
        ]
    }),

    methods:{
        async list(){

            if (
                this.$store.getters['security/datosUsuario'].roles.includes('ROLE_CIRUGIA_MED') &&
                !this.$store.getters['security/datosUsuario'].roles.includes('ROLE_ADMIN')
            ){
                this.params.username = this.$store.getters['security/datosUsuario'].username
            }

            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/cirugia/listCirugia',
                    method:'GET',
                    params: this.params,
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root[0]
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
            },
    },

}
</script>

<style>

</style>